@use "../../styles/variables.module.scss" as *;

.listbox {
  margin: 0 auto;
  max-width: 68rem;
  padding: 6rem 2rem;
}

.listitem {
  background: $color-gray-lighten-3;
  padding: 1.6rem;
  margin-bottom: 0.5rem;
  border-radius: 0.6rem;
  color: $color-black;
  cursor: pointer;
  &__name {
    margin-left: 1rem;
  }
  &__empty {
    margin-left: 1rem;
    color: $color-black;
  }
  &:hover {
    color: $color-primary;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4rem 0;
  text-align: center;
}
